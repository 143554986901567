<template>
    <div class="d-flex justify-center fill-height page">
        <v-container class="d-flex align-center justify-center flex-column mx-6">
            
            <transition :name="codeSent ? 'slide-right' : 'slide-left'" mode="out-in">
                <v-card :flat="phoneOnly" v-if="!codeSent && !tokenReceived" class="form-wrapper pb-8" :class="{'transparent': phoneOnly }" :key="2">
                    <v-form @submit.prevent="sendCode">
                        <v-card-title class="justify-center mb-3">Reset Account Password</v-card-title>
                        <v-card-subtitle class="grey--text details-text text-center mb-7 mx-4">
                            We will send a code to your email, then you will be able to change your password using this code.
                        </v-card-subtitle>
                        <v-text-field placeholder="Enter your Account Email " label="Email" name="email" v-model="email" ref="emailInput" :loading="loading" :disabled="loading" outlined hide-details></v-text-field>
                        <v-btn type="submit" block depressed large :disabled="loading || !email" :loading="loading" color="primary" class="mt-5" @click.prevent="sendCode">Send Code</v-btn>
                    </v-form>
                </v-card>

                <v-card :flat="phoneOnly" v-else-if="codeSent && !tokenReceived" class="form-wrapper pb-8" :key="1" :class="{'transparent': phoneOnly }">
                    <v-form ref="codeForm" @submit.prevent="validateCodeAction">
                        
                        <v-card-title class="justify-center mb-3">Enter Code</v-card-title>
                        <v-card-subtitle class="grey--text details-text text-center mb-7 mx-4">We have sent a 4-digit code to your email. You must enter it here to reset the password of your account.</v-card-subtitle>
                        
                        <digits-input :loading="loading" :auto-focus="true" @change="updateCode" />
                        <v-btn type="submit" block depressed large :disabled="!code || code.length !== 4 || loading || !email" :loading="loading" color="primary" class="mt-4" @click.prevent="validateCodeAction">Check code</v-btn>
                    </v-form>
                </v-card>
        
                <v-card :flat="phoneOnly" v-else class="form-wrapper pb-8" :key="3" :class="{'transparent': phoneOnly }">
                    <v-form ref="passwordForm" @submit.prevent="newPassword">
                        <v-card-title class="justify-center font-weight-bold primary--text mb-3">Enter New Password</v-card-title>
                        <v-card-subtitle class="grey--text details-text text-center mb-7 mx-4">Enter new password to use it in logging to your account</v-card-subtitle>
                        <v-text-field outlined placeholder="Enter New Password" label="new password"  name="password" v-model="password" :loading="loading" :disabled="loading" :rules="[rules.global.required, ...rules.password]" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword"></v-text-field>
                        <v-text-field outlined placeholder="Confirm New Password" label="confirm password"  name="confirm_password" :loading="loading" :disabled="loading" :rules="[rules.global.required, ...rules.confirmPassword]" v-model="confirmPassword" :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showConfirmPassword ? 'text' : 'password'" @click:append="showConfirmPassword = !showConfirmPassword"></v-text-field>
                        <v-btn type="submit" block depressed large :disabled="loading || !email" :loading="loading" color="primary" class="mt-2" @click.prevent="newPassword">Change password</v-btn>
                    </v-form>
                </v-card>

            </transition>

            <div class="footer mt-auto mt-md-9 mx-auto py-3">
                <v-btn v-if="!tokenReceived" text :disabled="loading" large @click="codeSent ? codeSent = false : goBack()">
                    <v-icon left>mdi-arrow-left</v-icon>
                    {{codeSent ? 'send again' : 'Back'}}
                </v-btn>
            </div>
        </v-container>
    </div>
</template>

<script>

// @ is an alias to /src
import digitsInput from '../components/default/digits-input'

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

export default {
    name: 'resetPassword',
    data(){
        return {
            email: '',
            loading: false,
            codeSent: false,
            tokenReceived: false,
            code: '',

            password: '',
            confirmPassword: '',
            resetPasswordUrl: '',

            showPassword: false,
            showConfirmPassword: false,
            rules: {
                global: {
                    required: value => !!value || 'Input is Required'
                },
                email: [
                    value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || 'Email is not valid';
                    }
                ],
                password: [
                    value => value.length >= 6 || "Min password length is 6"
                ],
                confirmPassword: [
                    value => value === this.password || 'Passwords do not match'
                ]
            },

        };
    },
    components: {
        digitsInput
    },
    computed:{
        appLoadedOnce(){
            return this.$store.state.loaded;
        },
        ...GlobalComputed,
    },
    methods: {
        ...GlobalMethods,
        sendCode(){
            const self = this;
            let email = this.email;
                
            if (email){
                let validateResponse = this.rules.email[0](email);
                if ( validateResponse === true){

                    this.loading = true;
                    
                    this.$store.dispatch('sendCodeAction', {email}).then(()=>{
                        this.showMsg("Code sent to your email successfully", 'success', 2000);
                        this.codeSent = true;
                        this.loading = false;
    
                    }).catch(error=>{

                        // handle errors, ex: user has no email in their account .. etc
                        if (error.response && error.response.status === 404){
                            this.showMsg("Email you entered is not related to any account");
                        }else if (error.response.status === 422){
                            this.showMsg("Email is not valid");
                        }else{
                            this.showMsg("Error occurred while sending your request...");
                        }
                        
                        this.loading = false;
                        setTimeout(()=>{
                            self.$refs.emailInput.focus();
                        }, 150);
                    })
                    
                }else{
                    this.showMsg(validateResponse);
                    setTimeout(()=>{
                        self.$refs.emailInput.focus();
                    }, 150);
                }

            }else{
                this.showMsg("Enter your Account Email ");
                setTimeout(()=>{
                    self.$refs.emailInput.focus();
                }, 150);
            }
        },
        validateCodeAction(){
            // const self = this;
            let email = this.email,
                code = this.code,
                pattern = /^[0-9]{4}$/g;
            // check if reponse if parseInt for code is int, and length = 4
            if (code && pattern.test(code.toString())){
                
                this.loading = true;
                this.$store.dispatch('validateCodeAction', {email, code}).then(response=>{

                    if (response.data.resetUrl){
                        this.resetPasswordUrl = response.data.resetUrl;
                        this.tokenReceived = true;
                        this.loading = false;
                    }else{
                        this.showMsg("A");
                    }

                }).catch(error=>{
                    // this.$refs.codeForm.reset();
                    // handle errors, ex: user has no email in their account .. etc
                    if (error.response){
                        if (error.response.data.msg === 'EXPIRED_CODE'){
                            this.showMsg("Code is expired");
                        }else if (error.response.data.msg === 'INVALID_CODE'){
                            this.showMsg("Code is invalid");
                        }else{
                            this.showMsg("Error occurred during checking code, try again");
                        }
                    }else{
                        this.showMsg("Error occurred during checking code, try again...");
                    }
                    this.loading = false;
                })
                
            }else{
                this.showMsg("Enter code correctly");
            }
        },
        newPassword(){
            let password = this.password,
                url = this.resetPasswordUrl;
            if (this.$refs.passwordForm.validate()){
                
                this.loading = true;
                this.$store.dispatch('newPasswordAction', {password, url}).then(()=>{

                    this.loading = false;
                    this.showMsg("Your password changed successfully, you can login to your account now!", 'success', 2000);
                    window.setTimeout(()=>{
                        this.$router.push({name: 'login'});
                    }, 1000);

                }).catch(()=>{

                    // handle errors, ex: user has no email in their account .. etc
                    this.showMsg("Error occurred, code maybe expired");
                    
                    this.loading = false;
                })

            }else{
                this.showMsg("Enter a new password correctly");
            }
        },
        updateCode(code){
            this.code = code;
        },
        showMsg(text, color = 'error', timeout = 4000){
            this.$store.dispatch('addSnackMsg', {show: true, text: text, color: color, timeout: timeout, msgBar: true});
        },
        closeMsg(){
            if (this.$store.state.globalModule.snackMsg.text){
                this.$store.dispatch('addSnackMsg', {show: false, text: '', msgBar: true});
            }
        },
        goBack(){
            this.$router.go(-1);
            // this.$router.push({path: '/'});
        },
    },
    watch: {
        email(){
            this.closeMsg();
        },
        password(){
            this.closeMsg();
        },
        codeSent(sent){
            if (!sent){
                // this.$refs.codeForm.reset();
            }
        },
        code(){
            // this.closeMsg();
        }
    },
}
</script>

<style lang="scss" scoped>
    .logo-wrapper{
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .logo{
            max-width: 150px;
            min-width: 120px;
            height: auto;
        }
    }
</style>